import * as React from "react";
import * as Axios from 'axios';
import axios from 'axios';
import { Balance, Transaction, QRData } from "@src/Models";
import {store} from "@src/Reducers";
import {logout, pushMessage} from "@src/Actions";
import {Icon} from "@src/App";
import * as dateFns from "date-fns";

const client: Axios.AxiosInstance = axios.create({
	baseURL: "/api"
});
client.interceptors.request.use(function(config) {
	if (localStorage.getItem("user") !== null && config.method != "OPTIONS") {
		let accessToken: string = localStorage.getItem("user");
		try {
			let jwt: { nbf: number, exp: number } = JSON.parse(atob(accessToken.split(".")[1]));
			let now: number = Math.ceil(Date.now() / 1000);
			if (now >= jwt.exp)
				localStorage.removeItem('user');
			else if ((jwt.nbf + (jwt.exp - jwt.nbf) * 0.75) <= now && config.url != "/auth/refresh-token")
				getRefreshToken().then((accessToken: string) => {
					localStorage.setItem('user', accessToken);
				}).catch(() => {
					console.log("Timeout logout");
					localStorage.removeItem('user');
				});
			if (accessToken !== undefined)
				config.headers = { ...config.headers, Authorization: "Bearer " + accessToken }
		} catch(error) {
			store.dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Invalid credentials. Please reauthenticate.</p></div>));
			store.dispatch(logout());
		}
	}
	return config;
})
client.interceptors.response.use(response => response, error => {
	if(error.response.status == 401 || error.response.status == 403) {
		store.dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Invalid credentials. Please reauthenticate.</p></div>));
		store.dispatch(logout());
	}
	else return Promise.reject(error);
});
export function getAuthUrl(clientId: string, redirect: string): Promise<string> {
	return client.get("/auth/url", {
		params: {
			client: clientId,
			redirect: redirect
		}
	}).then((response: Axios.AxiosResponse<any>) => {
		return response.data.url;
	});
}
export function isLoggedIn(): boolean {
	let accessToken: string = localStorage.getItem("user");
	if(accessToken == null) return false;
	try {
		let jwt: { nbf: number, exp: number } = JSON.parse(atob(accessToken.split(".")[1]));
		let now: number = Math.ceil(Date.now() / 1000);
		if(now >= jwt.exp) {
			localStorage.removeItem('user');
			return false;
		}
		return true;
	} catch(error) {
		store.dispatch(pushMessage(<div className="message"><Icon name="attention" /><p>Invalid credentials. Please reauthenticate.</p></div>));
		store.dispatch(logout());
	}
}
function getRefreshToken(): Promise<string> {
	return client.get("/auth/refresh-token").then((response: Axios.AxiosResponse<any>) => {
		return response.data.accessToken;
	});
}
export function sendAuthCode(): Promise<any> {
	return client.get("/auth/authenticate" + window.location.search).then((response: Axios.AxiosResponse<any>) => {
		return response.data;
	});
}
export function registerUser(userData: any): Promise<any> {
	return client.put("/auth/register", userData).then((response: Axios.AxiosResponse<any>) => {
		return response.data;
	});
}
export function getQrData(pay: number, charge: number): Promise<QRData> {
	return client.get("/customer/qr", {
		params: {
			pay: pay,
			charge: charge
		},
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			return {
				...data,
				expires: dateFns.parse(data.expires, "yyyy-MM-dd HH:mm:ss", 0)
			}
		}]
	}).then((response: Axios.AxiosResponse<QRData>) => {
		return response.data;
	});
}
export function getBalance(): Promise<Balance> {
	return client.get("/customer/balance").then((response: Axios.AxiosResponse<Balance>) => {
		return response.data;
	});
}
export function getProfile(): Promise<any> {
	return client.get("/auth/me").then((response: Axios.AxiosResponse<any>) => {
		return response.data;
	});
}
export function saveProfile(profile: any): Promise<any> {
	return client.put("/auth/me", profile).then((response: Axios.AxiosResponse<any>) => {
		return response.data;
	});
}
export function getTransactions(start: number, length: number): Promise<Transaction[]> {
	return client.get("/customer/transactions", {
		params: {
			start: start,
			length: length
		},
		transformResponse: [(rawData: string) => {
			let data = JSON.parse(rawData);
			if(Array.isArray(data))
				return data.map(item => {
					return {
						...item,
						date: new Date(item.date)
					};
				});
			return rawData;				
		}]
	}).then((response: Axios.AxiosResponse<Transaction[]>) => {
		return response.data;
	});
}
